.OutputText {
  font-size: 1.2em;
}

.OutputText a:link,
.OutputText a:visited {
  color: #bed;
}

.OutputText a:hover,
.OutputText a:focus,
.OutputText a:active {
  color: #deb;
}

.OutputText_percentage {
  font-size: 1.5em;
  font-style: oblique;
}
