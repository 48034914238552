.App {
  margin: 3em auto;
  max-width: 30em;
  text-align: center;
}

.App_error {
  color: #f00;
}

.App_input {
  background-color: #ddd;
  border: none;
  font: inherit;
  line-height: 2;
  margin: 1em 0 1.2em;
  padding-left: 0.5em;
  width: 75%;
}
